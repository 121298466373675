<template>
  <div>
    <!--Результаты поиска-->
    <div v-if="showResult">
      <v-divider />
      <div class="overline mb-4">
        Найдено результатов {{ objPagination.result_count }}:
      </div>
      <template>

        <div v-for="(obj, index) in objResultSpecialization" :key="index">
          <div class="d-none">{{ obj.keywords }}</div>
          <v-card class="media" hover outlined>
            <div class="media-body">
              <v-row>
                <v-col
                  cols="3"
                  sm="2"
                  class="d-flex justify-center align-center flex-column"
                >
                      <v-avatar class="profile" color="grey" size="64" tile>
                        <v-img
                            v-if="obj.doctorAvatar"
                            :src="apiAddress + obj.doctorAvatar"
                        ></v-img>
                        <v-img v-else src="~images/base_img_doctor.webp"></v-img>
                      </v-avatar>
                    <span>
                      <v-rating
                          :empty-icon="icons.mdiStarOutline"
                          :full-icon="icons.mdiStar"
                          hover
                          :value="obj.userRating"
                          readonly
                          size="30"
                          length="3"
                          background-color="primary"
                      ></v-rating>
                    </span>
                </v-col>

                <v-col cols="8" sm="4" class="d-flex flex-column">
                  <div>
                    <router-link
                      :to="{
                        path: '/doctor/' + obj.doctorId,
                        params: { userId: obj.doctorId },
                      }"
                      target="_blank"
                    >
                      <h5 class="media-heading" itemprop="description">
                        {{ obj.doctorName }}
                      </h5>
                      <div class="doctor-specialization">
                        {{ obj.specializationName }}
                      </div>
                    </router-link>

                    <template v-if="$resize && $mq.above(577)">
                      <router-link
                        :to="{
                          path: '/department/' + obj.departmentId,
                          params: { depId: obj.departmentId },
                        }"
                        target="_blank"
                      >
                        <span :class="b('department')"
                          >{{ obj.departmentName }}
                          <span v-if="obj.cityPart">
                            , {{ obj.cityPart }}
                          </span>
                        </span>
                      </router-link>
                      <br>
                      <template v-if="obj.distance">
                        <span :class="b('distance')">
                          До этого врача {{
                          Math.floor(obj.distance.kilometers * 100) / 100
                        }} километра </span>
                      </template>
                    </template>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="d-flex justify-start align-center"
                >
                  <div>
                    <template v-if="obj.serviceName && isFirstService">
                      <template v-if="$resize && $mq.between([0, 576])">
                        <router-link
                          :to="{
                            path: '/department/' + obj.departmentId,
                            params: { depId: obj.departmentId },
                          }"
                          target="_blank"
                        >
                          <span :class="b('department')"
                            >{{ obj.departmentName }}, {{ obj.cityPart }}</span
                          >
                        </router-link>
                        <template v-if="obj.distance">
                        <span :class="b('distance')">
                          До этого врача {{
                            Math.floor(obj.distance.kilometers * 100) / 100
                          }} километра
                        </span>
                        </template>
                      </template>

                      <span :class="b('price')" itemprop="name"
                        >{{ obj.serviceName }}:<br />
                      </span>
                      <span :class="b('price')" itemprop="price">
                        <b>{{
                          obj.doctorServicePrice || obj.servicePrice
                        }}</b></span
                      >
                      <br />
                    </template>
                  </div>
                </v-col>

                <v-col
                  cols="12"
                  sm="3"
                  class="text-center btn-container d-flex flex-row flex-sm-column align-center justify-center"
                >
                  <v-btn
                    outlined
                    class="btn-detail mr-2 mb-0 mb-sm-2 mr-sm-0"
                    color="primary"
                    v-if="obj.hiddenLk == 0"
                    @click="openDrawerDetail(obj.departmentId, obj.doctorId)"
                  >
                    Подробнее
                  </v-btn>

                  <v-btn
                    outlined
                    class="btn-order"
                    color="success"
                    v-if="isFirstService && obj.hiddenLk == 0"
                    @click="openDrawerFast(obj)"
                  >
                    Записаться
                  </v-btn>
                  <v-btn
                    outlined
                    color="red"
                    v-if="isFirstService && obj.hiddenLk == 1"
                    class="fz-10"
                    @click="notAvailable"
                  >
                    Запись ограничена
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </div>
      </template>
      <div class="flex-shrink-0">
        <div
                class="pagination text-center pb-4 pt-3"
                v-show="objPagination.actual_count >= 2 && showResult"
        >
          <v-pagination
                  v-model="page"
                  :length="objPagination.actual_count"
                  :prev-icon="icons.mdiMenuLeft"
                  :next-icon="icons.mdiMenuRight"
                  @input="searchEntity"
          >
          </v-pagination>
        </div>
      </div>
    </div>

    <!--Если результатов поиска не найдено-->
    <div v-show="showNotResult">
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-circular>
      <div v-else>
        <not-found />
      </div>
    </div>
  </div>
</template>
<script>
import * as names from "store/names";
import isEmpty from "lodash/isEmpty";
import { mapGetters, mapActions } from "vuex";
import NotFound from "./MainResultNotFound";
import {mdiMenuLeft, mdiMenuRight, mdiStar, mdiStarOutline} from "@mdi/js";
export default {
  name: "MainResultSpecialization",
  props: ["data"],
  components:  {NotFound},
  created() {
    this.searchEntity(false);
  },
  data: () => ({
    value: [],
    result: [],
    loading: false,
    isFirstService: true,
    page: 1,
    icons: {
      mdiMenuRight,
      mdiMenuLeft,
      mdiStarOutline,
      mdiStar,
    },
  }),
  mounted() {
    this.loading = true;
    setTimeout(() => (this.loading = false), 3000);
  },
  watch: {
    selectedSortValue() {
      this.searchEntity(true);
    },
    selectedTypeValue() {
      this.searchEntity(true);
    },
    selectedPrice() {
      this.searchEntity(true);
    },
    selectedCityParts() {
      this.searchEntity(true);
    },
    selectedMyCoordinatesLong() {
      this.searchEntity(true);
    },
    selectedMyCoordinatesLat() {
      this.searchEntity(true);
    },
  },
  computed: {
    ...mapGetters({
      selectedSortValue: names.SELECTED_SORT_VALUE,
      selectedTypeValue: names.SELECTED_TYPE_VALUE,
      selectedCityParts: names.SELECTED_CITY_PARTS,
      selectedPrice: names.SELECTED_PRICE_VALUE,
      selectedMyCoordinatesLat: names.SELECTED_MY_COORDINATES_LAT,
      selectedMyCoordinatesLong: names.SELECTED_MY_COORDINATES_LONG,
      objPagination: names.OBJ_SEARCH_PAGINATION,
      objResultSpecialization: names.OBJ_SEARCH_SERVICE_BY,
      docId: names.DOCTOR_ID,
      selectedCity: names.OBJ_SELECTED_CITY,
    }),

    apiAddress() {
      return names.API_ADDRESS;
    },

    showResult() {
      return !isEmpty(this.objResultSpecialization);
    },

    showNotResult() {
      return (
        this.data.entityName !== null && isEmpty(this.objResultSpecialization)
      );
    },
  },
  methods: {
    ...mapActions({
      mainResultSpecialization:
        names.ACTION_MAIN_SEARCH_DOCTOR_DEPARTMENT_SERVICE_BY_SPECIALIZATION_ID,
      selectedDoctorInfo: names.ACTION_SAVE_SELECTED_DOCTOR_INFO,
      saveDepId: names.ACTION_SAVE_DEPARTMENT_ID,
      clearResult: names.ACTION_CLEAR_OBJ_SERVICE_BY,
      saveDoctorId: names.ACTION_SAVE_DOCTOR_ID,
    }),
    async searchEntity(update) {
      if (update === false) {
        this.clearResult();
      }
      this.mainResultSpecialization({
        specializationId: (this.data.entityId || this.data.tag.specialization_id),
        cityId: this.selectedCity.id,
        sort: this.selectedSortValue,
        filterChildren: this.selectedTypeValue,
        filterPriceStart: this.selectedPrice[0],
        filterPriceEnd: this.selectedPrice[1],
        cityPart: this.selectedCityParts,
        myCoordinatesLat: this.selectedMyCoordinatesLat,
        myCoordinatesLong: this.selectedMyCoordinatesLong,
        update: update,
        page: this.page
      });
    },

    async saveSelectDoctorInfo(obj) {
      this.selectedDoctorInfo({
        specializationId: obj.specializationId,
        doctorName: obj.doctorName,
        cityId: this.selectedCity.id,
        serviceName: obj.serviceName,
        serviceId: obj.serviceId,
        servicePrice: obj.servicePrice,
        doctorServicePrice: obj.doctorServicePrice,
        departmentName: obj.departmentName,
        departmentId: obj.departmentId,
        cityPart: obj.cityPart,
        childDoc: obj.childrensMd,
      });
    },

    openDrawerDetail(depId, docId) {
      this.saveDepId(depId);
      this.saveDoctorId(docId);
      this.$drawer.showDrawer(true, "Detail");
      document.querySelector("body").style.overflow = "hidden";
    },

    openDrawerFast(obj) {
      this.saveSelectDoctorInfo(obj);
      this.$drawer.showDrawer(true, "Fast");
      document.querySelector("body").style.overflow = "hidden";
    },
    notAvailable(){
      return this.$notifier.showMessage("В данный момент запись к данному доктору не возможна!", "error");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~scss/helpers/helpers";

span {
  color: #000;
  font-family: $sanspro;
  font-size: 14px;
}
.doctor-specialization {
  color: $grey-blue;
  font-family: $sanspro;
  font-size: 14px;
  @include smaller-phone {
    padding-left: 20px;
    margin-left: 5px;
  }
}

.media {
  text-align: left;
  margin-top: 15px;
  overflow: visible;
  padding: 10px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 6px 5px #e3ecfc;
  }

  a {
    text-decoration: none;
  }

  .media-heading {
    font-size: 20px;
    @include smaller-phone {
      padding-left: 20px;
      margin-left: 5px;
    }
  }

  .btn-container {
    @include smaller-phone {
      margin-top: 20px;
    }

    button {
      width: 120px;
      height: 35px;
      font-size: 13px;
      background: initial;
      text-transform: none;
      @include phone-only {
        width: 100px;
        height: 35px;
      }
    }
  }
}
</style>
